import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "border-b mb-6"
};
const _hoisted_2 = {
  key: 0,
  class: "text-[14px] font-bold text-stone-500 tracking-widest mb-1"
};
const _hoisted_3 = {
  class: "font-bold text-stone-800 text-[32px]"
};
const _hoisted_4 = {
  class: "font-regular text-[16px] leading-[28px] text-stone-500 mb-6"
};
export default {
  __name: 'CreateLibraryHeader',
  props: {
    currentNum: String,
    totalNum: String
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.currentNum && __props.totalNum ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.currentNum) + "/" + _toDisplayString(__props.totalNum), 1)) : _createCommentVNode("", true), _createElementVNode("h1", _hoisted_3, [_renderSlot(_ctx.$slots, "title")]), _createElementVNode("p", _hoisted_4, [_renderSlot(_ctx.$slots, "description")])]);
    };
  }
};