import { api } from "@/api"
import { estimateKeywordVolume } from "@/utils"

export async function getPageGsc(libraryId, pageId) {
	const res = await api.get(`api/libraries/${libraryId}/pages/${ pageId }/gsc`)

	if (res?.data?.rows?.length > 0) {
		return res.data.rows.map(key => {
			key.keyword = key.keys[0]
			key.volume = estimateKeywordVolume(key.position, key.impressions)
			return key
		})
			.sort((a,b) => a.impressions > b.impressions ? -1 : 1 )
			.sort((a,b) => a.clicks > b.clicks ? -1 : 1 )
	} else {
		return []
	}
}
