
export function estimateKeywordVolume(position, impressions) {
	if (position === undefined || impressions === undefined) return undefined

	if (impressions > 10) {
		const volume = Math.round(impressions * Math.cbrt((Math.min(position, 50) + 1) / 2))
		return volume > 1000 ? Math.round(volume / 100) * 100 : Math.round(volume / 10) * 10
	} else {
		return undefined
	}
}