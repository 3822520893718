import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
};
const _hoisted_2 = {
  class: "text-sm font-semibold text-gray-700 tooltip-wrapper group hover:cursor-default"
};
const _hoisted_3 = {
  class: "mt-1 text-sm text-gray-800 sm:mt-0 sm:col-span-2"
};
export function render(_ctx, _cache) {
  const _component_hero_information_circle_16_solid = _resolveComponent("hero-information-circle-16-solid");
  const _component_BaseTooltip = _resolveComponent("BaseTooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("dt", _hoisted_2, [_renderSlot(_ctx.$slots, "title"), _ctx.$slots.tooltip ? (_openBlock(), _createBlock(_component_hero_information_circle_16_solid, {
    key: 0,
    class: "inline text-stone-400 w-3 h-3 mb-2 group-hover:text-stone-500"
  })) : _createCommentVNode("", true), _ctx.$slots.tooltip ? (_openBlock(), _createBlock(_component_BaseTooltip, {
    key: 1,
    size: "lg"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "tooltip")]),
    _: 3
  })) : _createCommentVNode("", true)]), _createElementVNode("dd", _hoisted_3, [_renderSlot(_ctx.$slots, "default")])]);
}