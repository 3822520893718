import dayjs from "dayjs"

const daysToSkip = 3
const monthlyKeys = []
for (let i = 1; i <= 12; i++ ) {
	const start = dayjs().subtract(daysToSkip, "days").subtract(13 - i, "month").startOf("month")
	const end = dayjs().subtract(daysToSkip, "days").subtract(13 - i, "month").endOf("month")
	monthlyKeys.push({ start, end })
}

export const emptyData = {
	sessions: 0,
	pageviews: 0,
	bounces: 0,
	bounceRate: 0
}

export class TimeSeries {
	constructor(rawData, type, syncDate) {
		this.hasDays = type != "page"
		this.earliestDate = dayjs()
		this.latestDate = dayjs().year(1900)
		this.data = []
		this.searchableData = {}
		this.syncDate = syncDate

		if (rawData?.length > 0) {
			rawData.forEach(item => {
				if (type == "page") {
					const date = dayjs(`${item.year}-${item.month}-01`)
					item.start_date = date.startOf("month")
					item.end_date = date.endOf("month")
					item.type = "month"
				} else {
					item.start_date = dayjs(item.start_date).startOf("day")
					item.end_date = dayjs(item.end_date).endOf("day")
					item.type = getDataType(item)
				}

				item.data = formatData(item)
				this.earliestDate = item.start_date.valueOf() < this.earliestDate.valueOf() ? item.start_date : this.earliestDate
				this.latestDate = item.end_date.valueOf() > this.latestDate.valueOf() ? item.end_date : this.latestDate

				this.addTimePeriod(item)
			})
		}

		this.daysAlive = Math.round((dayjs().subtract(daysToSkip, "days") - this.earliestDate.valueOf()) / 86400000)
		this.earliestDateDisplay = this.earliestDate.format("MMM YYYY")
		this.monthly = type == "page" ? this.getMonthlySequence() : undefined
	}

	addTimePeriod(item, days = undefined) {
		const thisPeriod = new TimePeriod(item, days)
		this.data.push(thisPeriod)
		this.searchableData[thisPeriod.key] = this.data.length - 1
		return thisPeriod
	}

	getMonthlySequence() {
		return monthlyKeys.map(key => {
			const exactIndex = this.searchableData[getSearchKey(key.start, key.end)]
			const exact = this.data[exactIndex]
			return exact?.data?.pageviews || 0
		})
	}

	getTimeSequence(length, type, skip = 0) {
		let iterator = !length ? this.earliestDate.startOf("day") : dayjs().subtract(daysToSkip, "days").subtract((length * 2) + skip, type).startOf("day")
		const endPoint = dayjs().subtract(daysToSkip, "days").subtract(skip, type).endOf("day")
		const sequence = []
		while (iterator.valueOf() < endPoint.valueOf()) {
			let item = {
				type,
				start_date: iterator.startOf(type),
				end_date: iterator.endOf(type),
			}
			const exactIndex = this.searchableData[getSearchKey(item.start_date, item.end_date)]
			const exact = this.data[exactIndex]

			if (exact) {
				sequence.push(exact)
			} else if (this.hasDays) {
				const days = this.data.filter(d => {
					const i = d.start_date.valueOf()
					return i >= item.start_date.valueOf() && i <= item.end_date.valueOf() && d.type == "day"
				})
				sequence.push(this.addTimePeriod(item, days))
			} else {
				sequence.push(this.addTimePeriod(item))
			}

			iterator = iterator.add(1, type)
		}

		return sequence
	}

	getData(length, type) {
		let skip = 1
		if (type == "day" && this.syncDate) {
			skip = Math.abs(dayjs().diff(this.syncDate, "day")) + 1
		}
		const data = this.getTimeSequence(length, type, skip)
		const rollup = basicRollup(data.slice(-length))
		const compare = basicRollup(data.slice(-(length * 2), -length))

		rollup.previous = compare
		rollup.bounceRate = rollup.bounces / rollup.sessions || 0

		if (compare?.pageviews > 0) {
			rollup.growthPercent = (rollup.pageviews - compare.pageviews) / compare.pageviews
			rollup.growthMetric = rollup.growthPercent * rollup.pageviews
		}
		return rollup
	}

	formatData(data) {
		const dataObj = {}
		if (Array.isArray(data)) {
			dataObj.sessions = data.reduce((sum, day) => sum + day.sessions, 0)
			dataObj.pageviews = data.reduce((sum, day) => sum + day.page_views, 0)
			dataObj.bounces = data.reduce((sum, day) => sum + day.bounces, 0)
		} else {
			dataObj.pageviews = data?.page_views || 0
			dataObj.bounces = data?.bounces || 0
			dataObj.sessions = data?.sessions || 0
		}
		dataObj.bounceRate = dataObj.sessions != 0 ? dataObj.bounces / dataObj.sessions : 0
		return dataObj
	}

	getGraphData(length, type, datapoint, projected = false) {
		const latest = dayjs().subtract(daysToSkip, "day")
		const format = type === "week" || type === "day" ? "M/D" : "MMM YY"
		const labels = []
		let sequence = this.getTimeSequence(length, type)
		sequence = length ? sequence.slice(-length) : sequence
		const cgr = getCgr(sequence)

		const data = sequence.map((period,index) => {
			if (!projected) {
				return period.data ? period.data[datapoint] : undefined
			}

			// don't try to project data for individual days, or when we don't have a syncDate
			if (!this.syncDate || period.type === "day") return undefined

			// growthProjected is what we guess the partial period will be based on the run of previous periods
			// dataProjected is what we guess the partial period will be based on where it's currently tracking, agnostic of other periods
			let growthProjected, dataProjected
			const dayLength = Math.abs(period.start_date.diff(period.end_date, "day")) + 1
			const dayActual = Math.abs(period.start_date.diff(this.syncDate, "day")) + 1
			const percentFinished = Math.min(dayActual / dayLength, 1)

			const current = this.syncDate.valueOf() <= period.end_date.valueOf() && this.syncDate.valueOf() >= period.start_date.valueOf()
			if (current) {
				const previous = sequence[index - 1]
				if (previous?.data && previous?.data[datapoint]) {
					growthProjected = Math.round(Math.max(previous.data[datapoint] + (previous.data[datapoint] * cgr), 0))
				}

				if (period.data && period.data[datapoint]) {
					dataProjected = Math.round((period.data[datapoint] / dayActual) * dayLength)
				}
			}

			if (growthProjected && dataProjected) {
				// if we have both sources, we incrementally prefer dataProjected as the period gets closer to finishing
				const growthProjectedModified = growthProjected * (1 - percentFinished) 
				const dataProjectedModified = dataProjected * percentFinished
				return Math.round(growthProjectedModified + dataProjectedModified)
			} else if (dataProjected) {
				return dataProjected
			} else if (growthProjected) {
				return growthProjected
			} else {
				return undefined
			}
		})

		// backfill empty data or undefined for the length of the requested period
		if (length !== undefined && data.length < length) {
			for (let i = length - data.length; i > 0; i--) {
				data.unshift(projected ? undefined : 0)
			}
		}

		// build labels for the time period
		length = data?.length ? data.length : length
		for (let i = length - 1; i >= 0; i--) {
			labels.push(latest.subtract(i, type).format(format))
		}

		return { data, labels }
	}
}


class TimePeriod {
	constructor(item, days) {
		this.key = getSearchKey(item.start_date, item.end_date)
		this.start_date = item.start_date
		this.end_date = item.end_date
		this.type = item.type
		this.days = days

		if (days) {
			this.complete = days?.length == this.dayLength
			this.data = formatData(days.map(day => day.data))
		} else if (item.data) {
			this.complete = true
			this.data = item.data
		} else {
			this.data = emptyData
		}
	}
}

function getSearchKey (start, end) {
	return start.format("YYYY-MM-DD") + end.format("YYYY-MM-DD")
}

function basicRollup(items) {
	if (!items?.length > 0) return emptyData
	const base = {
		bounces: items.filter(item => item.data).reduce((total, current) => total + current.data.bounces, 0),
		sessions: items.filter(item => item.data).reduce((total, current) => total + current.data.sessions, 0),
		pageviews: items.filter(item => item.data).reduce((total, current) => total + current.data.pageviews, 0),
	}

	base.start_date = items[0].start_date
	base.end_date = items[items.length - 1].end_date

	base.cgr = getCgr(items)
	base.avg = {
		bounces: Math.round(base.bounces / items.length),
		sessions: Math.round(base.sessions / items.length),
		pageviews: Math.round(base.pageviews / items.length),
	}
	return base
}

function getDataType (item) {
	if (item.start_date.date() === 1 && item.start_date.month() === item.end_date.month() && item.end_date.date() === item.start_date.endOf("month").date()) {
		return "month"
	} else if (item.start_date.format("YYYY-MM-DD") === item.end_date.format("YYYY-MM-DD")) {
		return "day"
	} else {
		return undefined
	}
}

export function formatData(data) {
	const dataObj = {}
	if (Array.isArray(data)) {
		dataObj.sessions = data.reduce((sum, item) => sum + item.sessions, 0)
		dataObj.pageviews = data.reduce((sum, item) => sum + item.pageviews, 0)
		dataObj.bounces = data.reduce((sum, item) => sum + item.bounces, 0)
	} else {
		dataObj.pageviews = data?.page_views || 0
		dataObj.bounces = data?.bounces || 0
		dataObj.sessions = data?.sessions || 0
	}
	dataObj.bounceRate = dataObj.sessions != 0 ? dataObj.bounces / dataObj.sessions : 0
	return dataObj
}

function getCgr(data) {
	let a, b, c, d, e, slope, yIntercept
	let dpsLength, xSum = 0, ySum = 0, xySum = 0, xSquare = 0

	data = data.map(period => {
		return period?.data?.pageviews || 0
	})

	const firstNonZero = data.findIndex(el => el > 0)

	if (firstNonZero < 0) return 0
	
	data = data.slice(Math.max(firstNonZero - 1, 0))
	dpsLength = data.length

	for (let i = 0; i < dpsLength; i++) {
		xySum += (data[i] * i)
	}

	a = xySum * dpsLength

	for (let i = 0; i < dpsLength; i++){  	
		xSum += i
		ySum += data[i]
	}

	b = xSum * ySum

	for (let i = 0; i < dpsLength; i++) {
		xSquare += Math.pow(i, 2)    
	}

	c = dpsLength * xSquare
	d = Math.pow(xSum, 2)
	const div = c - d == 0 ? 0.01 : c - d
	slope = (a - b) / (div)  

	e = slope * xSum
	yIntercept = (ySum - e) / dpsLength

	// use y = mx + b (y = slope * x + yIntercept) here to graph individual points
	// slope / yIntercept returns base level growth percent
	// zeroing the yIntercept so when we calculate CMGR we don't work with negative numbers, this probably gives false high percentages for nearly empty datasets
	let start = (slope * 0) + Math.max(yIntercept, 0.01)
	let end = (slope * dpsLength) + Math.max(yIntercept, 0.01)

	// formula for CMGR
	return (end / start) ** (1 / dpsLength) - 1
}

