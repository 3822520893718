import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, withKeys as _withKeys, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";

import { useLibraryFormStore } from '@/stores';
import { ref } from 'vue';
const _hoisted_1 = {
  class: "mb-12"
};
export default {
  __name: 'CreateLibraryName',
  setup(__props) {
    const form = useLibraryFormStore();
    const v$ = form.v$;
    const footer = ref(null);
    return (_ctx, _cache) => {
      const _component_CreateLibraryHeader = _resolveComponent("CreateLibraryHeader");
      const _component_BaseInputLabel = _resolveComponent("BaseInputLabel");
      const _component_BaseInputText = _resolveComponent("BaseInputText");
      const _component_CreateLibraryFooter = _resolveComponent("CreateLibraryFooter");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_CreateLibraryHeader, {
        "current-num": "1",
        "total-num": "6"
      }, {
        title: _withCtx(() => [_createTextVNode(" Create a library ")]),
        description: _withCtx(() => [_createTextVNode(" Libraries are groups of content that are the basis for our prioritized recommendations and analytics. Depending on the size of your site, you might want to create separate libraries for different buckets of content (e.g. your marketing site, your docs site and your community site). ")]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_BaseInputLabel, {
        for: "library-name"
      }, {
        default: _withCtx(() => [_createTextVNode(" Library name ")]),
        _: 1
      }), _createVNode(_component_BaseInputText, {
        state: _unref(form).libraryName,
        "onUpdate:state": _cache[0] || (_cache[0] = $event => _unref(form).libraryName = $event),
        validation: _unref(v$).libraryName,
        "onUpdate:validation": _cache[1] || (_cache[1] = $event => _unref(v$).libraryName = $event),
        class: "mt-2",
        name: "library-name",
        placeholder: "'Blog Pages', 'Marketing Site', etc.",
        onKeypress: _cache[2] || (_cache[2] = _withKeys($event => _unref(footer) ? _unref(footer).nextClick() : null, ["enter"]))
      }, null, 8, ["state", "validation"])]), _createVNode(_component_CreateLibraryFooter, {
        ref_key: "footer",
        ref: footer,
        "next-to": "CreateLibraryAnalytics",
        "previous-to": "WorkspaceManageUsers",
        "next-enabled": !_unref(v$).libraryName.$invalid && _unref(form).libraryName !== null
      }, null, 8, ["next-enabled"])], 64);
    };
  }
};