import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "text-base font-normal text-gray-900"
};
const _hoisted_2 = {
  key: 0,
  class: "mt-1 flex justify-between items-baseline md:block lg:flex"
};
const _hoisted_3 = {
  class: "text-2xl font-bold text-gray-900"
};
const _hoisted_4 = {
  key: 0,
  class: "mx-3 text-xs text-gray-500 font-normal"
};
const _hoisted_5 = {
  key: 1,
  class: "mt-1 flex justify-between items-baseline md:block lg:flex"
};
const _hoisted_6 = {
  class: "text-2xl font-bold text-gray-900"
};
const _hoisted_7 = {
  key: 2,
  class: "mt-1 h-[32px] w-28 animate-pulse bg-gray-100 rounded-lg"
};
import { numDisplay } from "@/utils/math";

import { computed } from 'vue';
export default {
  __name: 'LibraryDataBox',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    },
    subtitle2: {
      type: String,
      default: ""
    },
    description: String,
    data: undefined,
    subdata: undefined,
    display: {
      type: String,
      default: "m"
    },
    subdisplay: {
      type: String,
      default: "m"
    },
    classes: {
      type: String,
      default: ""
    },
    textClasses: {
      type: String,
      default: ""
    },
    clickable: {
      type: Boolean,
      default: false
    },
    percentChange: Number,
    active: Boolean
  },
  setup(__props) {
    const props = __props;
    const displayData = computed(() => {
      if (typeof props.data == "number") {
        return numDisplay(props.data, props.display);
      } else {
        return props.data;
      }
    });
    return (_ctx, _cache) => {
      const _component_hero_arrow_down_16_solid = _resolveComponent("hero-arrow-down-16-solid");
      const _component_hero_arrow_up_16_solid = _resolveComponent("hero-arrow-up-16-solid");
      const _component_SimpleTooltip = _resolveComponent("SimpleTooltip");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["p-6 tooltip-wrapper group select-none", [__props.classes, {
          'hover:cursor-pointer': __props.clickable,
          'bg-opacity-0 hover:bg-opacity-5': !__props.active,
          'bg-opacity-5 hover:bg-opacity-10': __props.active
        }]])
      }, [_createElementVNode("dt", _hoisted_1, _toDisplayString(__props.title), 1), __props.data || __props.data == 0 ? (_openBlock(), _createElementBlock("dd", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", {
        class: _normalizeClass(__props.textClasses)
      }, _toDisplayString(_unref(displayData)), 3), __props.subdata || __props.subdata == 0 ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.subtitle) + " " + _toDisplayString(_unref(numDisplay)(__props.subdata, __props.subdisplay)) + " " + _toDisplayString(__props.subtitle2), 1)) : _createCommentVNode("", true)]), __props.percentChange ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["rounded-lg font-bold border px-2 py-1 text-xs flex items-center", {
          'bg-red-100 text-red-700 border-red-200': __props.percentChange < 0,
          'bg-green-100 text-green-700 border-green-200': __props.percentChange >= 0
        }])
      }, [__props.percentChange < 0 ? (_openBlock(), _createBlock(_component_hero_arrow_down_16_solid, {
        key: 0,
        class: "w-3"
      })) : (_openBlock(), _createBlock(_component_hero_arrow_up_16_solid, {
        key: 1,
        class: "w-3"
      })), _createTextVNode(" " + _toDisplayString(_unref(numDisplay)(Math.abs(__props.percentChange), "%s")), 1)], 2)) : _createCommentVNode("", true)])) : __props.data === null ? (_openBlock(), _createElementBlock("dd", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("span", {
        class: _normalizeClass(["text-gray-300", __props.textClasses])
      }, "NA", 2)])])) : (_openBlock(), _createElementBlock("dd", _hoisted_7)), __props.description ? (_openBlock(), _createBlock(_component_SimpleTooltip, {
        key: 3,
        class: "left-0",
        "tool-text": __props.description
      }, null, 8, ["tool-text"])) : _createCommentVNode("", true)], 2);
    };
  }
};