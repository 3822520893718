import { api } from "@/api"
import dayjs from "dayjs"
import { estimateKeywordVolume } from "@/utils"
import { useLibraryStore } from "@/stores"

export async function getLibraryGscQueryAndPage(libraryId, options) {
	const libraryStore = useLibraryStore()

	const body = {
		"startDate": options.startDate || dayjs().subtract(31, "day").format("YYYY-MM-DD"),
		"endDate": options.endDate || dayjs().subtract(1, "day").format("YYYY-MM-DD"),
		"rowLimit": options.rowLimit || 10,
		"dimensions": ["QUERY","PAGE"],
		"dimensionFilterGroups": [
			{
				"groupType": "AND",
				"filters": [
					{
						"dimension": options.regexDimension || "QUERY",
						"operator": options.regexType || "INCLUDING_REGEX",
						"expression": options.regex,
					},
				],
			},
		],
	}

	if (libraryStore.gscPageFilters?.length > 0) {
		libraryStore.gscPageFilters.forEach(filter => {
			body.dimensionFilterGroups[0].filters.push(filter)
		})
	}

	const res = await api.post(`api/libraries/${libraryId}/gsc`, body)

	if (res?.data?.rows?.length > 0) {

		const keywordLibrary = {}

		res.data.rows.forEach(row=> {
			const keyword = row.keys[0]
			const url = row.keys[1]
			const impressions = row.impressions
			const clicks = row.clicks
			const position = row.position
			const thisPage = { url, clicks, impressions, position }

			if (keywordLibrary[keyword]) {
				keywordLibrary[keyword].clicks += row.clicks
				keywordLibrary[keyword].impressions += row.impressions
				keywordLibrary[keyword].pages.push(thisPage)
			} else {
				keywordLibrary[keyword] = { keyword, clicks, impressions, pages: [thisPage] }
			}
		})

		return Object.keys(keywordLibrary).map(key => {
			const keyword = keywordLibrary[key]
			keyword.competition = 0
			keyword.position = keyword.pages[0].position
			keyword.topPage = keyword.pages[0]
			keyword.volume = estimateKeywordVolume(keyword.position, keyword.impressions)
			return keyword
		})
			.sort((a,b)=> a.impressions > b.impressions ? -1 : 1)
			.sort((a,b)=> a.clicks > b.clicks ? -1 : 1)

	} else {
		return []
	}
}