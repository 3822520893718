import "core-js/modules/es.array.push.js";
import { unref as _unref, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";

import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';
const _hoisted_1 = {
  class: "flex justify-end items-center mt-auto"
};
export default {
  __name: 'CreateLibraryFooter',
  props: {
    nextTo: {
      type: [Object, String],
      required: true
    },
    previousTo: {
      type: [Object, String],
      required: true
    },
    nextEnabled: Boolean,
    previousEnabled: {
      type: Boolean,
      default: true
    },
    nextShown: {
      type: Boolean,
      default: true
    },
    previousShown: {
      type: Boolean,
      default: true
    },
    beforePrevious: {
      type: Function,
      default: () => true
    },
    beforeNext: {
      type: Function,
      default: () => true
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const router = useRouter();
    const route = useRoute();
    const props = __props;
    const doingNext = ref(false);
    const doingPrevious = ref(false);
    async function nextClick() {
      if (!props.nextEnabled) return;
      doingNext.value = true;
      const res = await props.beforeNext();
      doingNext.value = false;
      if (res) {
        pushToRoute(props.nextTo);
      }
    }
    async function previousClick() {
      if (!props.previousEnabled) return;
      doingPrevious.value = true;
      const res = await props.beforePrevious();
      doingPrevious.value = false;
      if (res) {
        pushToRoute(props.previousTo);
      }
    }
    __expose({
      nextClick,
      previousClick
    });
    function pushToRoute(newRoute) {
      if (typeof newRoute === "object") {
        router.push(newRoute);
      } else if (typeof newRoute === "string") {
        router.push({
          name: newRoute,
          params: {
            workspaceId: route.params.workspaceId
          }
        });
      }
    }
    return (_ctx, _cache) => {
      const _component_ButtonDefault = _resolveComponent("ButtonDefault");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.previousShown ? (_openBlock(), _createBlock(_component_ButtonDefault, {
        key: 0,
        styles: "back",
        enabled: __props.previousEnabled && !_unref(doingPrevious),
        onClick: previousClick
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "back", {}, () => [_createTextVNode(" Back ")])]),
        _: 3
      }, 8, ["enabled"])) : _createCommentVNode("", true), __props.nextShown ? (_openBlock(), _createBlock(_component_ButtonDefault, {
        key: 1,
        enabled: __props.nextEnabled && !_unref(doingNext),
        styles: "primary",
        class: "ml-auto",
        onClick: nextClick
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "next", {}, () => [_createTextVNode(" Next ")])]),
        _: 3
      }, 8, ["enabled"])) : _createCommentVNode("", true)]);
    };
  }
};